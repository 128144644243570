import React, { useState, useEffect } from "react";
import axios from "axios";
import API_BASE_URL from "../../env";

const RecentApplications = () => {
  const [recentApplications, setRecentApplications] = useState([]);

  useEffect(() => {
    const fetchRecentApplications = async () => {
      try {
        const userId = JSON.parse(localStorage.getItem("user")).id;
        const response = await axios.get(
          `/api/applications/recent/user/${userId}`,
          {
            baseURL: API_BASE_URL,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setRecentApplications(response.data);
      } catch (error) {
        console.error("Error fetching recent applications:", error);
      }
    };

    fetchRecentApplications();
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div className="card" style={{background:"#8adcd2"}}>
      <div className="mt-1" style={{ textAlign: "center" }}></div>
      <h3>Reacent Applications</h3>
      <div>
        <ul>
          {recentApplications.map((app) => (
            <li
              style={{ listStyle: "none", height: "50px" }}
              key={app.id}
              className="mt-1 mb-2"
            >
              <h5>
                <div className="float-left mr-1">
                  <img
                    src={`${process.env.PUBLIC_URL}/user-40.png`}
                    alt="User"
                  />
                </div>
                <span>{app.user.fullName} </span>
                <small>({app.type})</small>
                <div className="float-right mt-2 mr-3">
                  <small
                    style={{
                      color:
                        app.status === "Approved"
                          ? "green"
                          : app.status === "Rejected"
                          ? "red"
                          : "orange",
                    }}
                  >
                    {app.status}
                  </small>
                </div>
                <p></p>
              </h5>
              <hr className="mt-2 mb-2" />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default RecentApplications;
