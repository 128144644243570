// constants/themes.js

const themes = {
  orange: {
    primary: "#FF8C00",
    secondary: "#FF8C00",
    text: "#FFFFFF",
    background: "#FFFFFF",
    hover: "#FFFF00",
    iconDefault: "#FFFFFF",
    iconHover: "#FFFF00",
    textActive: "#FFFF00",
    selectedBorder: "#FFFF00",
    panelBackground: "#F8F9FA",
    panelBorderColor: "#E0E0E0",
    infoboxBackground: "#FFFFFF",
    fontSize: "14px",
    padding: "10px 15px",
    leaveColor: "#FFD700",
    leaveTextColor: "#000000",
  },
  blue: {
    primary: "#0066CC",
    secondary: "#0099FF",
    text: "#FFFFFF",
    background: "#FFFFFF",
    hover: "#33CCFF",
    iconDefault: "#FFFFFF",
    iconHover: "#33CCFF",
    textActive: "#33CCFF",
    selectedBorder: "#33CCFF",
    panelBackground: "#F0F4F8",
    panelBorderColor: "#D0D0D0",
    infoboxBackground: "#FFFFFF",
    fontSize: "14px",
    padding: "10px 15px",
    leaveColor: "#4682B4",
    leaveTextColor: "#000000",
  },
  green: {
    primary: "#006633",
    secondary: "#005522",
    text: "#FFFFFF",
    background: "#FFFFFF",
    hover: "#00CC66",
    iconDefault: "#FFFFFF",
    iconHover: "#00CC66",
    textActive: "#00CC66",
    selectedBorder: "#00CC66",
    panelBackground: "#E8F5E9",
    panelBorderColor: "#C8E6C9",
    infoboxBackground: "#FFFFFF",
    fontSize: "14px",
    padding: "10px 15px",
    leaveColor: "#32CD32",
    leaveTextColor: "#000000",
    topColor: "#32CD32", // Lime Green for top
    bottomColor: "#98FB98", // Pale Green for bottom
    textColor: "#000000", // Black text color
    progressColor: "#32CD32", // Lime Green for the progress bar
    textSidebar: "#FFFFFF", // White text color for the sidebar
  },
  red: {
    primary: "#FF0000",
    secondary: "#CC0000",
    text: "#FFFFFF",
    background: "#FFCCCC",
    hover: "#FF9999",
    iconDefault: "#FF6666",
    iconHover: "#FF3333",
    textActive: "#FF0000",
    selectedBorder: "#CC0000",
    panelBackground: "#FFE5E5",
    panelBorderColor: "#FFCCCC",
    infoboxBackground: "#FFFFFF",
    fontSize: "14px",
    padding: "10px 15px",
    leaveColor: "#FF6347",
    leaveTextColor: "#000000",
  },
  purple: {
    primary: "#800080",
    secondary: "#9932CC",
    text: "#FFFFFF",
    background: "#E6E6FA",
    hover: "#DDA0DD",
    iconDefault: "#DA70D6",
    iconHover: "#BA55D3",
    textActive: "#800080",
    selectedBorder: "#9932CC",
    panelBackground: "#F3E5F5",
    panelBorderColor: "#E1BEE7",
    infoboxBackground: "#FFFFFF",
    fontSize: "14px",
    padding: "10px 15px",
    leaveColor: "#BA55D3",
    leaveTextColor: "#000000",
  },
  yellow: {
    primary: "#FFD700",
    secondary: "#FFA500",
    text: "#FFFFFF",
    background: "#FFFACD",
    hover: "#FFD700",
    iconDefault: "#FFA500",
    iconHover: "#FF8C00",
    textActive: "#FFD700",
    selectedBorder: "#FFA500",
    panelBackground: "#FFF9C4",
    panelBorderColor: "#FFF59D",
    infoboxBackground: "#FFFFFF",
    fontSize: "14px",
    padding: "10px 15px",
    leaveColor: "#FFD700",
    leaveTextColor: "#000000",
  },
  pink: {
    primary: "#FF69B4",
    secondary: "#FF1493",
    text: "#FFFFFF",
    background: "#FFC0CB",
    hover: "#FFB6C1",
    iconDefault: "#FF69B4",
    iconHover: "#FF1493",
    textActive: "#FF69B4",
    selectedBorder: "#FF1493",
    panelBackground: "#F8BBD0",
    panelBorderColor: "#F48FB1",
    infoboxBackground: "#FFFFFF",
    fontSize: "14px",
    padding: "10px 15px",
    leaveColor: "#FF69B4",
    leaveTextColor: "#000000",
  },
  teal: {
    primary: "#008080",
    secondary: "#20B2AA",
    text: "#FFFFFF",
    background: "#E0FFFF",
    hover: "#AFEEEE",
    iconDefault: "#40E0D0",
    iconHover: "#48D1CC",
    textActive: "#008080",
    selectedBorder: "#20B2AA",
    panelBackground: "#E0F7FA",
    panelBorderColor: "#B2EBF2",
    infoboxBackground: "#FFFFFF",
    fontSize: "14px",
    padding: "10px 15px",
    leaveColor: "#20B2AA",
    leaveTextColor: "#000000",
  },
};

export default themes;
