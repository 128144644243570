
export const banks = {
    banks: [
      "State Bank of India",
      "HDFC Bank",
      "ICICI Bank",
      "Axis Bank",
      "Kotak Mahindra Bank",
      "IndusInd Bank",
      "Yes Bank",
      "Punjab National Bank",
      "Bank of Baroda",
      "Canara Bank",
      "Union Bank of India",
      "Bank of India",
      "Indian Bank",
      "Central Bank of India",
      "IDBI Bank",
      "UCO Bank",
      "Indian Overseas Bank",
      "Bank of Maharashtra",
      "Punjab & Sind Bank",
      "Federal Bank",
      "South Indian Bank",
      "RBL Bank",
      "Karnataka Bank",
      "City Union Bank",
      "Karur Vysya Bank",
      "DCB Bank",
      "Tamilnad Mercantile Bank",
      "IDFC First Bank",
      "Jammu & Kashmir Bank",
      "Suryoday Small Finance Bank",
      "Ujjivan Small Finance Bank",
      "Equitas Small Finance Bank",
      "AU Small Finance Bank",
      "Bandhan Bank",
      "Dhanlaxmi Bank",
      "Nainital Bank",
      "North East Small Finance Bank",
      "ESAFF Small Finance Bank",
      "Fincare Small Finance Bank",
    ],
  };
  